var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-content"},[_c('v-sheet',{attrs:{"color":"white","elevation":"2"}},[_c('v-container',{staticClass:"tw-relative"},[_c('v-overlay',{staticClass:"tw-rounded-lg",attrs:{"value":_vm.isProcessing,"absolute":"","z-index":"1"}}),_c('v-progress-linear',{staticClass:"tw-rounded-t-xl",attrs:{"active":_vm.isProcessing,"indeterminate":_vm.isProcessing,"absolute":"","top":"","color":"grey darken-4"}}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doSubmit($event)}}},[_c('v-row',[_c('v-col',{staticClass:"tw-px-4 tw-pt-6 tw-pb-10",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.form.email,"error-messages":errors,"label":"E-mail","placeholder":"Email...","outlined":"","required":""},on:{"input":function (x) { return _vm.doUpdateData(x, 'email'); }}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"code","rules":"required|min:6|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{'vee-error': errors.length},attrs:{"value":_vm.form.code,"error-messages":errors,"label":"Code","type":"text","placeholder":"Enter code here...","outlined":"","required":""},on:{"input":function (x) { return _vm.doUpdateData(x, 'code'); }}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|verify_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"tw-flex tw-relative"},[_c('v-text-field',{class:{'vee-error': errors.length},attrs:{"value":_vm.form.password,"error-messages":errors,"label":"Password","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Password...","outlined":"","required":""},on:{"input":function (x) { return _vm.doUpdateData(x, 'password'); }}}),_c('v-img',{staticClass:"tw-absolute tw-right-3 tw-top-3.5 tw-cursor-pointer",attrs:{"max-height":"24","max-width":"24","src":_vm.showPassword ? require('@/assets/icons/ic-eye-open.svg') :require('@/assets/icons/ic-eye-close.svg')},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})],1)]}}],null,true)}),_c('div',[_c('v-btn',{staticClass:"tw-w-full tw-mb-3",attrs:{"color":"primary","type":"submit","disabled":invalid,"large":""}},[_vm._v(" Update Password ")])],1),_c('div',{staticClass:"tw-text-center tw-py-0 tw-flex tw-flex-col tw-justify-between"},[_c('div',[_c('v-btn',{staticClass:"tw-cursor-pointer",attrs:{"plain":"","disabled":_vm.isResend},on:{"click":function($event){return _vm.doResendCode()}}},[_c('small',[_vm._v("Resend Code")])]),(_vm.isResend)?_c('span',{staticClass:"tw-px-1"},[_c('small',[_vm._v("after "+_vm._s(_vm.timeleft)+" seconds")])]):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"tw-cursor-pointer",attrs:{"plain":""}},[_c('router-link',{staticClass:"tw-underline",attrs:{"to":{ name: _vm.ROUTE_SIGNIN }}},[_c('small',[_vm._v("Log In")])])],1)],1)])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }